import React, { useState } from "react"
import styled from "styled-components"

import GlobalStateProvider from "../context/provider"
import ContentWrapper from "../styles/contentWrapper"
import Layout from "../components/layout"
import SEO from "../components/seo"

const StyledSection = styled.section`
  width: 100%;
  max-width: 76rem;
  margin: 0 auto;
  padding: 0 2.5rem;
  padding-left: 8.5rem;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.25rem;
  }
  h3 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-left: 2.5rem;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    margin: 0;
    padding: 0;
    height: 100%;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  margin-top: 24px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.container};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  @media (max-width: 500px) {
    flex-direction: column;
    margin-bottom: 0;
    justify-content: center;
    align-items: center;
  }
`

const Column = styled.div`
  width: 100%;
  max-width: ${props => (props.fullWidth ? "100%" : "48%")};
  @media (max-width: 500px) {
    max-width: 100%;
  }
`

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  margin-bottom: 20px;
`

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  margin-bottom: 28px;
  resize: vertical;
  min-height: 130px;
`

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.opposite};
  border-radius: 5px;
  border: none;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 20px;
`

const FormError = styled.div`
  color: ${({ theme }) => theme.colors.red};
`

const ErrorMessage = styled.div`
  background-color: ${({ theme }) => theme.colors.redBG};
  text-align: center;
  margin-top: 10px;
  padding: 12px 40px;
  border-radius: 5px;
`
const Title = styled.h1`
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.12rem;
  text-transform: uppercase;
`
const SuccessMessage = styled.div`
  background-color: ${({ theme }) => theme.colors.greenBG};
  text-align: center;
  margin-top: 10px;
  padding: 12px 40px;
  border-radius: 5px;
`

const Success = styled.div`
  color: ${({ theme }) => theme.colors.green};
`

const validateEmail = email => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

const Support = () => {
  const globalState = {
    isIntroDone: true,
    darkMode: false,
  }
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [whatsappNumber, setWhatsappNumber] = useState("")
  const [message, setMessage] = useState("")
  const [formError, setFormError] = useState(null)
  const [formSuccess, setFormSuccess] = useState(false)

  const handleSubmit = async event => {
    event.preventDefault()
    setFormSuccess(false)
    if (!firstName) return setFormError("First name is required")
    if (!lastName) return setFormError("Last name is required")
    if (!email) return setFormError("Email is required")
    if (!whatsappNumber) return setFormError("Whatsapp number is required")
    if (!message) return setFormError("Message is required")
    if (!validateEmail(email)) return setFormError("Invalid email address")

    const values = {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Whatsapp: whatsappNumber,
      Message: message,
    }

    const response = await fetch("https://formspree.io/f/xnqyrvaq", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(values),
    })
    if (response.status !== 200) {
      return setFormError("Something went wrong, please try again later")
    } else {
      setFirstName("")
      setLastName("")
      setEmail("")
      setWhatsappNumber("")
      setMessage("")
      setFormError(null)
      setFormSuccess(true)
    }
  }

  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout>
        <SEO title="Support" meta={[{ name: "robots", content: "noindex" }]} />
        <StyledSection id="Support">
          <StyledContentWrapper>
            <Container>
              <Title>Support</Title>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Column>
                    <Label htmlFor="firstName">First Name</Label>
                    <Input
                      type="text"
                      id="firstName"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                    />
                  </Column>
                  <Column>
                    <Label htmlFor="lastName">Last Name</Label>
                    <Input
                      type="text"
                      id="lastName"
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Label htmlFor="email">Email</Label>
                    <Input
                      type="email"
                      id="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </Column>
                  <Column>
                    <Label htmlFor="whatsappNumber">WhatsApp Number</Label>
                    <Input
                      type="tel"
                      id="whatsappNumber"
                      value={whatsappNumber}
                      onChange={e => setWhatsappNumber(e.target.value)}
                    />
                  </Column>
                </Row>
                <Label htmlFor="message">Message</Label>
                <TextArea
                  id="message"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                />
                <Button type="submit">Submit</Button>
              </Form>
              {formError && (
                <ErrorMessage>
                  <FormError>{formError}</FormError>
                </ErrorMessage>
              )}
              {formSuccess && (
                <SuccessMessage>
                  <Success>Your message has been sent!</Success>
                </SuccessMessage>
              )}
            </Container>
          </StyledContentWrapper>
        </StyledSection>
      </Layout>
    </GlobalStateProvider>
  )
}

export default Support
